<template>
  <home :indexData="indexData" />
</template>

<script>
import home from './home';
import { getBannerInfo, recommendInfo } from '@/api/website/home';
import { setDevelopCustomerServiceParams } from '@/common/localStorage';
export default {
  name: 'Index',
  components: { home },
  data() {
    return {
      indexData: {},
    };
  },
  created() {
    this.initDevelopCS();
    this.getData();
  },
  methods: {
    async getData() {
      const [bannerData, grossProfitData, hotBrandData, hotPackageData] = await Promise.all([
        getBannerInfo(),
        recommendInfo('GROSS_PROFIT'),
        recommendInfo('HOT_BRAND'),
        recommendInfo('HOT_PACKAGE'),
      ]);
      const indexData = {
        bannerList: bannerData.data,
        grossProfitList: grossProfitData.data,
        hotBrandList: hotBrandData.data,
        hotPackageList: hotPackageData.data,
      };
      this.indexData = indexData;
    },
    initDevelopCS(){
      const { developCSId = '', type = '', timestamp = '' } = this.$route.query;
      if(developCSId && type) {
        const params = { developCSId, type, timestamp };
        setDevelopCustomerServiceParams(params);
        this.$store.dispatch('bindDevelopCustomerService', params);
      }
    }
  },
};
</script>
