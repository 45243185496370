<template>
  <div class="home">
    <oss-image
      src="https://oss.syounggroup.com/static/file/soyoung-zg/official-website/top-banner.png"
      alt=""
      class="home__img"
    />
    <div class="home__img-box" ref="imgBox">
      <div class="home__nav" ref="nav">
        <div
          :class="activeIndex === index ? 'home__label-active home__label' : 'home__label'"
          v-for="(item, index) in labelList"
          :key="index"
          :href="'#' + index"
          @click="jumpLink(index, item.id)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="home__kv-box">
        <div v-for="(item, index) in labelList" :key="index" :id="'anchor-' + index">
          <oss-image
            :src="img.brandIndexKvPlaceholder || ''"
            alt=""
            v-for="(img, idx) in item.brandWebsiteExtInfoList"
            :height="img.brandIndexKvPlaceholder ? 'auto' : imgDefaultHeight"
            :key="index + '__' + idx"
            class="home__kv"
            @click="clickBrand(img)"
          />
        </div>
      </div>
      <div class="home__place"></div>
    </div>
    <oss-image
      src="https://oss.syounggroup.com/static/file/soyoung-zg/official-website/home__info.png"
      alt=""
      class="home__info"
    />
  </div>
</template>

<script>
import { listLabelAndBrandInfo } from '@/api/website/home';
import eventReporter from '@/utils/event-reporter';
import debounce from 'lodash/debounce';

import TopBar from '@/components/views/website/home-new/TopBar.vue';
export default {
  name: 'Home',
  components: {
    TopBar,
  },
  props: {
  },
  data() {
    return {
      debouncedPageScrollHandler: null,
      imgDefaultHeight:
        (((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) *
          0.94 -
          300) /
          1520) *
        630,
      activeIndex: 0, // 标签激活的序号
      labelList: [], // 标签列表
    };
  },
  mounted() {
    this.getData();
    this.debouncedPageScrollHandler = debounce(this.scrolls, 10);
    window.addEventListener('scroll', this.debouncedPageScrollHandler);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.debouncedPageScrollHandler);
  },
  methods: {
    clickBrand(brand) {
      eventReporter.trackClick(this.$route, {
        name: 'homeBrandKvPicture',
        aliasName: '品牌KV图',
        query: brand.id,
        event_source: 'home_brand_kv_picture',
      });
      // 需要在运营端启用并且开启在商家端显示
      if (brand.needShow === '1' && brand.status === '1') {
        this.$router.push('/syoung/commodity/list?brandId=' + brand.id);
      } else {
        this.$message.error('暂未在平台售卖，请联系专属顾问');
      }
    },
    jumpLink(idx, id) {
      eventReporter.trackClick(this.$route, {
        name: 'homeBrandLabel',
        aliasName: '品牌标签',
        query: id,
        event_source: 'home_brand_label',
      });
      // this.activeIndex = idx;
      const anchor = this.$el.querySelector('#anchor-' + idx);
      document.documentElement.scrollTop = anchor.offsetTop;
    },
    scrolls() {
      const currentScrollTop =
        (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) + 1;
      const labelList = this.labelList;
      // 依次和各节点原先的offsetTop进行比较
      for (let i = 0; i < labelList.length; i++) {
        const anchor = this.$el.querySelector('#anchor-' + i);
        const top = anchor.offsetTop;
        const next = this.$el.querySelector('#anchor-' + (i + 1));
        let breakFlag = false;
        // 如果scrollTop正好和某节点的offsetTop相等
        // 或者scrollTop介于当前判断的节点和下一个节点之间
        // 由于需要下一个节点，所以当前节点不能是最后一个节点
        if (
          currentScrollTop === top ||
          (i < labelList.length - 1 && currentScrollTop > top && currentScrollTop < next.offsetTop)
        ) {
          this.activeIndex = i;
          breakFlag = true;
        } else if (i === labelList.length - 1) {
          // 如果判断到一个节点，只要 scrollTop大于节点的offsetTop即可
          if (currentScrollTop > top) {
            this.activeIndex = i;
            breakFlag = true;
          }
        }

        // 如果当前区域被激活，图片可以加载出来
        this.loadImg(this.activeIndex);
        // 如果不是最后一个区域，把当前元素的下一个也加载出来
        if (this.activeIndex !== labelList.length - 1) {
          this.loadImg(this.activeIndex + 1);
        }
        if (breakFlag) {
          break;
        }
      }
    },
    loadImg(idx) {
      const imgList = this.labelList[idx].brandWebsiteExtInfoList || [];
      this.labelList[idx].brandWebsiteExtInfoList = imgList.map(v => {
        return {
          ...v,
          brandIndexKvPlaceholder: v.brandIndexKv,
        };
      });
    },
    getData() {
      listLabelAndBrandInfo().then(res => {
        if (res.code === '0' && res.data) {
          this.labelList = [...res.data];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  background: #030e1f;
  min-width: 1200px;
  padding-bottom: 130px;

  &__img-box {
    width: 94%;
    display: flex;
  }

  &__img {
    display: inline-block;
    width: 100%;
    min-height: 300px;
  }

  &__place {
    width: 150px;
    flex: none;
  }

  &__nav {
    flex: none;
    position: sticky;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    top: 0;
    left: 0;
    font-size: 23px;
    color: #ffffff;
    text-align: right;
    width: 150px;
    height: 100vh;
  }

  &__label-active {
    font-size: 30px;
    font-weight: 600;
  }

  &__label {
    margin-top: 40px;
    box-sizing: border-box;
    padding-right: 30px;
    cursor: pointer;
    width: 100%;
    flex: none;
  }

  &__kv-box {
    flex: 1;
  }

  &__kv {
    margin-top: 60px;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
    background: #fff;
    background-image: url('https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/logoLevel/logoName_180_50.png');
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: center;
  }

  &__info {
    width: 80%;
    min-width: 960px;
    display: block;
    margin: 130px auto 0 auto;
  }
}
</style>
